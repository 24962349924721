import { FC } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, To } from 'react-router-dom';

interface NavigationLinkProps {
  to: To;
  icon: IconDefinition;
  title: string;
}

export const NavigationLink: FC<NavigationLinkProps> = ({ to, icon, title }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${isActive ? 'text-grose-blue border-b-2 border-blue-600' : 'text-gray-700 hover:text-grose-blue-hover hover:border-b-2 hover:border-blue-700'} ml-8 pb-[14px]`
    }
  >
    <FontAwesomeIcon icon={icon} />
    <span className="ml-1">{title}</span>
  </NavLink>
);
