import { useQuery } from '@tanstack/react-query';
import { fetchUser } from '../api/fetchUser';

const useUser = (domain: string) => useQuery({
    queryKey: ['user'],
    queryFn: () => fetchUser(domain),
    retry: (failureCount, error) => error.message.includes('401') ? false : failureCount < 3,
  });

export default useUser;
