import { useEffect, useState } from 'react';

const OfflineBanner = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOffline) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center bg-red-500 px-1 pb-2 pt-1 text-center text-sm text-white sm:text-base">
      <p>Internetové připojení není dostupné. Prosím zkontrolujte svoje internetové připojení.</p>
    </div>
  );
};

export default OfflineBanner;
