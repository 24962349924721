import axios from 'axios';
import { getOptions } from './options';

type AddOrEditUrl = (
  domain: string,
  id: number,
  addSubfolder: string,
  editSubfolder: string,
) => string;

const addOrEditUrl: AddOrEditUrl = (domain, id, addSubfolder, editSubfolder) =>
  `${domain}/api/v1/${id > 0 ? `${editSubfolder}/${id}` : addSubfolder}`;

export const api = {
  get: <T = any>(url: string): Promise<T> =>
    axios.get(url, getOptions()).then((response) => response.data),
  getCookie: <T = any>(url: string): Promise<T> => axios.get(url),
  post: <T = any, D = any>(url: string, data?: D): Promise<T> =>
    axios.post(url, data, getOptions()).then((response) => response.data),
  postWithoutOptions: <T = any, D = any>(url: string, data?: D): Promise<T> =>
    axios.post(url, data).then((response) => response.data),
  save: <T = any, D = any>(
    domain: string,
    id: number,
    addSubfolder: string,
    editSubfolder: string,
    data?: D,
  ): Promise<T> =>
    axios
      .post(addOrEditUrl(domain, id, addSubfolder, editSubfolder), data, getOptions())
      .then((response) => response.data),
};
