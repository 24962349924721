import { useQuery } from '@tanstack/react-query';
import { fetchConfig } from '../api/fetchConfig';

const useConfig = (domain: string) =>
  useQuery({
    queryKey: ['config'],
    queryFn: () => fetchConfig(domain),
    retry: (failureCount, error) => (error.message.includes('401') ? false : failureCount < 3),
  });

export default useConfig;
