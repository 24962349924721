import { FC } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import { NavLink, To } from 'react-router-dom';

interface NavigationLinkMobileProps {
  to: To;
  icon: IconDefinition;
  title: string;
}

export const NavigationLinkMobile: FC<NavigationLinkMobileProps> = ({ to, icon, title }) => (
  <Menu.Item>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `${isActive ? 'text-grose-blue' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2`
      }
    >
      <FontAwesomeIcon icon={icon} />
      <span className="ml-2">{title}</span>
    </NavLink>
  </Menu.Item>
);
