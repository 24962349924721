import { createContext } from 'react';
import { User } from '../types/User';

interface UserContextData {
  user: User | null;
  isLoading: boolean;
}

export const UserContext = createContext<UserContextData>({
  user: null,
  isLoading: false,
});
