interface AlertProps {
  type: 'success' | 'error';
  text: string;
}

function Alert({ type, text }: AlertProps) {
  let classes = 'w-full flex rounded-lg p-4 mb-4 text-sm';
  if (type === 'error') {
    classes += ' bg-red-100 text-red-700';
  } else {
    classes += ' bg-green-100 text-green-700';
  }

  return (
    <div className={classes} role="alert">
      <svg
        className="mr-3 inline size-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
      <div>{text}</div>
    </div>
  );
}

export default Alert;
