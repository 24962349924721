import { useState, useEffect } from 'react';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SendVerificationEmailResponse } from '../../types/API/SendVerificationEmailResponse';
import { User } from '../../types/User';
import Button, { ButtonColors } from '../elements/Button/Button';

interface VerifyEmailGuardPageProps {
  user: User;
  onSendVerificationEmail: () => Promise<SendVerificationEmailResponse>;
}

const COOLDOWN_DURATION_MS = 5 * 60 * 1000;

function VerifyEmailGuardPage({ user, onSendVerificationEmail }: VerifyEmailGuardPageProps) {
  const [isSending, setIsSending] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    const updateRemainingTime = () => {
      if (!user.email_verification_send_at) {
        setRemainingTime(0);
        setCanResend(true);
        return;
      }

      const emailSentAt = new Date(user.email_verification_send_at);
      if (Number.isNaN(emailSentAt.getTime())) {
        setRemainingTime(0);
        setCanResend(true);
        return;
      }

      const now = new Date();
      const diffMs = now.getTime() - emailSentAt.getTime();
      const timeLeftMs = Math.max(COOLDOWN_DURATION_MS - diffMs, 0);
      setRemainingTime(timeLeftMs);
      setCanResend(timeLeftMs === 0);
    };

    updateRemainingTime();
    const interval = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(interval);
  }, [user.email_verification_send_at]);

  const handleSendVerificationEmail = async () => {
    setIsSending(true);
    await onSendVerificationEmail();
    setIsSending(false);
    setCanResend(false);
  };

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="flex h-96 flex-col items-center justify-center">
      <FontAwesomeIcon icon={faUserLock} className="mb-4 text-red-600" size="2x" />
      <h3 className="text-center text-2xl font-bold sm:text-3xl">Prosím ověřte váš e-mail</h3>
      <div className="mt-4">Právě byl odeslán e-mail na</div>
      <div className="text-lg font-bold">{user.email}</div>
      <div className="mt-6">Kliknutím na link v e-mailu ověříte váš účet.</div>
      <div className="mt-6">Nemůžete najít e-mail?</div>
      <div className="mt-6 flex flex-col items-center">
        <Button
          color={ButtonColors.Blue}
          text="Znovu odeslat ověřovací e-mail"
          onClick={handleSendVerificationEmail}
          disabled={!canResend || isSending}
          loading={isSending}
        />
        {!canResend && (
          <div className="mt-2 text-center text-sm text-gray-500">
            Prosím počkejte ještě {formatTime(remainingTime)} před znovuodesláním e-mailu.
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmailGuardPage;
