import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Loader() {
  return (
    <div className="flex h-screen items-center justify-center">
      <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-700" size="3x" />
    </div>
  );
}

export default Loader;
