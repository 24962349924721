import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum ButtonColors {
  Blue = 'blue',
  Red = 'red',
  White = 'white',
  GradientPurpleBlue = 'gradient-purple-blue',
  GradientPurpleRed = 'gradient-purple-red',
  GradientRedPurpleBlue = 'gradient-red-purple-blue',
}

interface ButtonProps {
  color: ButtonColors;
  text: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  showIconMobile?: boolean;
  className?: string;
}

function Button({
  color,
  text,
  onClick,
  loading,
  disabled,
  icon,
  showIconMobile,
  className,
}: ButtonProps) {
  const baseClasses =
    'h-10 w-full flex items-center justify-center md:w-auto py-2 px-8 focus:outline-none focus:shadow-outline-blue rounded-md transition duration-300';

  const colorClasses: Record<ButtonColors, string> = {
    [ButtonColors.Red]: 'text-white bg-red-500 hover:bg-red-600 cursor-pointer',
    [ButtonColors.GradientPurpleBlue]:
      'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl cursor-pointer',
    [ButtonColors.GradientPurpleRed]:
      'text-white bg-gradient-to-br from-purple-500 to-red-500 hover:bg-gradient-to-bl cursor-pointer',
    [ButtonColors.GradientRedPurpleBlue]:
      'text-white bg-gradient-to-br from-red-500 via-purple-500 to-blue-500 hover:bg-gradient-to-bl cursor-pointer',
    [ButtonColors.Blue]: 'text-white bg-blue-600 hover:bg-blue-700 cursor-pointer',
    [ButtonColors.White]: 'border text-gray-700 hover:bg-gray-50 cursor-pointer',
  };

  const disabledClasses = 'bg-gray-300 text-gray-700 cursor-not-allowed';

  const classes = `${className || ''} ${baseClasses} ${
    disabled ? disabledClasses : colorClasses[color]
  }`;

  return (
    <button
      type="submit"
      className={classes}
      onClick={onClick}
      disabled={disabled}
      aria-busy={loading}
    >
      {!loading && icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`${showIconMobile ? 'mr-2' : 'hidden md:mr-2 md:block'}`}
        />
      )}
      {!loading && text}
      {loading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
    </button>
  );
}

export default Button;
